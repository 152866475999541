/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
  width: auto;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

/* You can add global styles to this file, and also import other style files */

html, body { height: 100vh;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.cursor-pointer{
  cursor: pointer;
}

.cursor-default{
  cursor: default;
}

.background-img {
  background: url("/assets/img/background1.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.login-container {
  max-width: 500px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.space-nowrap.space-nowrap{
  width: 1px;
  white-space: nowrap;
  padding-right: .8rem;
}

.no-shadow {
  box-shadow: none !important;
}

.border-rad-5{
  border-radius: 5px;
}

.bg-white{
  background-color: rgb(129, 129, 129);
}

.snackbar-formater{
  background-color: white;
  color: rgb(42, 42, 42);
  border-left: 12px #fcd40d solid;
}

.snackbar-formater button{
  color: rgb(42, 42, 42);
}

@import "../../../node_modules/angular-calendar/css/angular-calendar.css"
